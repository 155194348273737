import * as React from "react"
import { Helmet } from "react-helmet"
import '../styles/mint.css'
import HomeSVG from "../images/2.0/home.svg";
const AccountWrapper = React.lazy(() =>
  import("../components/account/wrapper.js")
)

const AccountPage = () => {
  const isSSR = typeof window === "undefined"
  return (
      <main>
        <Helmet>
          <meta charSet="utf-8" />
          <title>MY ACCOUNT - CULT&RAIN&trade;</title>
          <link rel="canonical" href="https://www.cultandrain.com/account" />
          <meta name="description" content="The first luxury fashion brand born from the crypto universe merging NFTs with exclusive, physical redeemables. Join our Web3 revolution." />
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous" />
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>
        </Helmet>
        <div className="claim-page">
          {!isSSR && (
              <React.Suspense fallback={<div />}>
                  <AccountWrapper />
              </React.Suspense>
          )}
        </div>
      </main>
  )
}

export default AccountPage
